import React from "react";
import { Typography, Button, Link } from "@mui/material";
import ThankYouImage from "../../Assets/wvc-spreadsmile-three-children-laughing.png";

const ChildLockText = () => {
  // Define responsive font sizes once
  const responsiveFontSizesBody = {
    lg: "3.4vw",
    md: "3.4vw",
    sm: "3.4vw",
    xs: "3.4vw",
  };
  const responsiveFontSizesHeading = {
    lg: "9vw",
    md: "7vw",
    sm: "6vw",
    xs: "6vw",
  };
  return (
    <div className="childLockContainer">
      <img
        className="sponsored_image"
        src={ThankYouImage}
        alt="Three girls laughing."
      />
      <div className="childLockText">
        <Typography variant="h1" sx={{ fontSize: responsiveFontSizesHeading }}>
          Thank you for your interest in child sponsorship!
        </Typography>

        <Typography
          variant="p"
          sx={{
            fontSize: responsiveFontSizesBody,
          }}
        >
          This child has already found a sponsor, but many are still in need of
          your help.
        </Typography>
        <Link href="https://donate.worldvision.ca/collections/sponsorships" data-track-action="SPONSOR NOW" class="btnSponsornow" data-track-category="Button Link" data-track-label="https://donate.worldvision.ca/collections/sponsorships" >SPONSOR NOW</Link>
      </div>
    </div>
  );
};

export default ChildLockText;
