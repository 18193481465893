import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Box, Button, Typography, Grid, TextField } from "@mui/material";
import PageLoader from "./PageLoader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetFormData } from "../slices/donorinfoSlice";
import { resetChildData } from "../slices/childinfoSlice";
import { getData, postData } from "../service/SmileService";
// Custom styling for the Stripe elements
const stripeElementStyles = {
  base: {
    color: "#32325d",
    fontFamily: "Lato, Helvetica, sans-serif",
    fontSmoothing: "antialiased",
    fontSize: "1.15rem",
    "::placeholder": {
      color: "#0009",
    },
    border: "1px solid black", // Adjust border style
    height: "56px", // Adjust height
    borderRadius: "4px", // Adjust border radius if necessary
    padding: "10px 14px", // Adjust padding
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
  },
};

const CheckoutForm = () => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [success, setSuccess] = useState(false);
  const [cardholderName, setCardholderName] = useState("");
  const formData = useSelector((state) => state.form);
  const childData = useSelector((state) => state.child);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();

    const submitButton = event.nativeEvent.submitter;
    // Save form data to Redux store
    const trackAction = submitButton.getAttribute('data-track-action');
    const trackCategory = submitButton.getAttribute('data-track-category');
    const trackLabel = submitButton.getAttribute('data-track-label');
    const packageId = submitButton.getAttribute('data-package-id');

        // Push event to the dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'button_click',        // Custom event name for GTM
      action: trackAction,
      category: trackCategory,
      label: trackLabel,
      packageId: packageId
    });

    setLoading(true);
    if (!stripe || !elements) {
      return;
    }
    // setLoading(true);
    // const card = elements.getElement(CardElement);
    // if (card == null) {
    //     return;
    // }
    console.log("card holder name: " + cardholderName);
    console.log("Donor Details: " + formData);

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      return;
    }

    try {
        // Call your custom endpoint to create a Payment Intent
        // Create a dummy payload
        //   setLoading(true);

        console.log("Address line1 : "+ formData.address);
        console.log("Province : " + formData.province);
        console.log("city : " + formData.city);
        console.log("Postalcode : " + formData.pincode);
        const payload = {
          firstName: `${formData.firstName}`,
          lastName: `${formData.lastName}`,
          email: `${formData.email}`,
          lang: "en",
          address: {
            line1: `${formData.address}`,
            city: `${formData.city}`,
            province: `${formData.province}`,
            postalcode: `${formData.pincode}`,
            country: "Canada",
          },
          //
          subscriptionProducts: [
            {
              productId: process.env.REACT_APP_STRIPE_PRODUCT_KEY,
              frequency: process.env.REACT_APP_DONATION_FREQUENCY,
              paymentIntervalMultiplier: 1,
              amount: process.env.REACT_APP_DONATION_AMOUNT,
              quantity: 1,
              descode: process.env.REACT_APP_CAMPAIGN_DESC_CODE,
              pledgetype: process.env.REACT_APP_PRODUCT_PLEDGE_TYPE,
              projectid: childData.projectId,
              childnumber: childData.childNumber,
              country: childData.country,
              firstname: childData.firstName + " " + childData.lastName,
              gender: childData.genderCode,
              childImageUrl: childData.imageLatestUrl,
              birthDay: childData.birthday,
              age: childData.age
            },
          ],
          packageid: childData.packageId.toString(),
          source: process.env.REACT_APP_DONATION_SOURCE
        };
        
        console.log("data sent to txn service: ", JSON.stringify(payload));
        const response = await getData("", "/api/generate-token");

        let accessToken = response.access_token;
        console.log("access token: ", accessToken);

        let requestData = {
          url: process.env.REACT_APP_STRIPE_TRANSACTION_API_URL + 'create-payments', // The URL you want to hit
          data: payload,
          accessToken: accessToken
        };

        const data = await postData('','/api/create-transaction',requestData);
      
        console.log("data received from transaction service call:", data);

        const clientSecret = data[0].clientSecret;
        console.log("card holder name: " + cardholderName);
        // Confirm the card payment
        await stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: `${cardholderName}`,
              email: payload.email,
              address: {
                line1: payload.address.line1,
                line2: payload.address.line2,
                city: payload.address.city,
                state: payload.address.province,
                country: payload.address.postalCode,
              },
            },
          },
        })
        .then(async function (result) {
          if (result.error) {
            // Inform the customer that there was an error.
            console.log(result.error.message);
            setError(result.error.message);
            setLoading(false);
          } else {
            // Handle next step based on PaymentIntent's status.
            let apiResponse = await postData(process.env.REACT_APP_SPONSORSHIP_API_URL,
              "/child/lock/sponsored/" +
                childData.projectId +
                "-" +
                childData.childNumber
            );
            console.log("API Response from lock child service: " + apiResponse);
            setSuccess(true);
            console.log("PaymentIntent ID: " + result.paymentIntent.id);
            console.log("PaymentIntent status: " + result.paymentIntent.status);
            //dispatchEvent()
            
            dispatch(resetFormData());
            setLoading(false);
            // Redirect to another page upon successful payment
            navigate("/thankyou");
          }
        });
      } 
      catch (err) {
        setError(err.response ? err.response.data.error : err.message);
        dispatch(resetFormData());
        dispatch(resetChildData());
      }
};

  return (
    <>
      {loading ? (
        <>
          <PageLoader height="50%" />
        </>
      ) : (
        ""
      )}
      <Box sx={{ p: 0 }}>
        <Typography
          sx={{
            fontSize: { lg: "3vw", md: "23vw", sm: "3vw", xs: "3vw" },
            p: 2,
          }}
          textAlign={"center"}
          component="p"
          gutterBottom
        >
          Payment Information
        </Typography>
        <form className="regular-checkout" onSubmit={handleSubmit}>
          {/* <CardElement /> */}
          <Grid container spacing={0} className="stripeContainer">
            <Grid item xs={12}>
              <TextField
                label="Cardholder Name"
                variant="outlined"
                fullWidth
                size="small"
                value={cardholderName}
                onChange={(e) => setCardholderName(e.target.value)}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <CardNumberElement options={{ style: stripeElementStyles }} />
            </Grid>
            <Grid item xs={6} className="stripeCardExpire">
              <CardExpiryElement options={{ style: stripeElementStyles }} />
            </Grid>
            <Grid item xs={6} className="stripeCardCVV">
              <CardCvcElement options={{ style: stripeElementStyles }} />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            sx={{ mt: 2 }}
            disabled={!stripe}
            data-package-id={childData.packageId} 
            data-track-action="Submit" 
            data-track-category="Button" 
            data-track-label="Donate Now Button"
          >
            Donate Now
          </Button>
        </form>
        {error && (
          <Typography color="error" variant="body2" mt={2}>
            {error}
          </Typography>
        )}
        {success && (
          <Typography color="primary" variant="body2" mt={2}>
            Payment successful!
          </Typography>
        )}
      </Box>
    </>
  );
};

export default CheckoutForm;
